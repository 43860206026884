import Avatar from "@/components/avatar"
import { Skeleton } from "@/shared/components/skeletons/Skeleton"
import type { DateType, IAllocationPeriodData, IDate } from "@/types/glossary"
import type { PickByType } from "@/types/utils"
import { fetcher } from "@/utils/api"
import { cn, parseName } from "@/utils/helpers"
import {
  faPen,
  faTrashAlt,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "antd"
import dayjs from "dayjs"
import useSWR from "swr"
import { PeriodPills } from "./PeriodPills"

export type PeriodOptions = {
  value: PickByType<IAllocationPeriodData, "status"> | DateType
  icon: IconDefinition
  color: string
  label: string
}[]
interface IPeriod {
  period: IDate
  user?: Record<string, any>
  isSelected: boolean
  options: PeriodOptions
  onMarkAs(value: PickByType<IAllocationPeriodData, "status"> | DateType): void
  onSelect(): void
  onDelete(): void
}

export const Period = ({
  period,
  user,
  isSelected,
  options,
  onSelect,
  onMarkAs,
  onDelete,
}: IPeriod) => {
  const { data: prices } = useSWR(
    !!period?.key ? `/periods/${period.key}/prices` : null,
    fetcher,
  )
  const [price] = prices ?? []
  const { start, end } = period
  const PeriodPill = PeriodPills[period.type]

  return (
    <div
      className={cn(
        `hover:scale-102 flex cursor-pointer items-center justify-between gap-2 transition-all`,
        isSelected && "scale-102",
      )}
      onClick={onSelect}
    >
      <div className="flex items-center gap-2 text-xs">
        <div
          className={cn(
            "relative flex items-center gap-2",
            isSelected &&
              "before:absolute before:-left-4 before:h-2 before:w-2 before:rounded-full before:bg-blue-400",
          )}
        >
          {!!user && (
            <Avatar
              email={user.email}
              tooltip={parseName(user)}
              size={18}
              border={false}
            />
          )}
          <div
            className={cn(
              "flex items-center gap-2",
              isSelected && "text-blue-500",
            )}
          >
            <p>{dayjs(start).format("DD MMM YY")}</p>
            <span>-</span>
            <p>{end ? dayjs(end).format("DD MMM YY") : "Future"}</p>
            <p>
              {" "}
              {`${price ? `(${price.price / 100} ${price.currency.prefix})` : ""}`}
            </p>
          </div>
          {!!PeriodPill ? <PeriodPill period={period} /> : <></>}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Tooltip title="Edit period">
          <FontAwesomeIcon
            className="cursor-pointer text-gray-500"
            icon={faPen}
          />
        </Tooltip>
        {options.map((_option) => (
          <Tooltip key={_option.value} title={_option.label}>
            <FontAwesomeIcon
              className={cn("cursor-pointer rounded-full", _option.color)}
              onClick={() => onMarkAs(_option.value)}
              icon={_option.icon}
            />
          </Tooltip>
        ))}
        <Tooltip title="Remove period">
          <FontAwesomeIcon
            className="cursor-pointer text-red-400"
            icon={faTrashAlt}
            onClick={onDelete}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export const SkeletonPeriod = () => {
  return (
    <div className="flex items-center justify-between gap-2">
      <Skeleton className="h-4 w-48" />
      <div className="flex items-center gap-2">
        {Array.from({ length: 4 }).map((_, i) => (
          <Skeleton
            key={`skeleton-button-${i.toString()}`}
            className="h-5 w-5"
          />
        ))}
      </div>
    </div>
  )
}
