import { DATE_TYPES, IDate } from "@/types/glossary"
import { fetcher } from "@/utils/api"
import { faTreePalm } from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faDoorOpen } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import useSWR from "swr"
import { v4 } from "uuid"
import { selectedUserAtom, timelineAlertAtom } from "../utils/atoms"
import { EditPeriod } from "./EditPeriod"
import { OoOTypes, OutOfOfficeDateTypes } from "./ModalOutOfOffice"
import { Period, PeriodOptions, SkeletonPeriod } from "./Period"

type UserPeriodsProps = {
  user: string
  showAvatar: boolean
  onChange(uid: string, dates: IDate[]): void
}

export const UserPeriods = ({
  user: uid,
  showAvatar,
  onChange,
}: UserPeriodsProps) => {
  const [periods, setPeriods] = useState<IDate[]>([])
  const [selectedPeriod, setSelectedPeriod] = useState<IDate>()
  const { data: user, isLoading } = useSWR(
    uid ? `/users/${uid}` : null,
    fetcher,
  )
  const selectedUser = useRecoilValue(selectedUserAtom)
  const alertState = useRecoilValue(timelineAlertAtom)
  const periodOptions: PeriodOptions = [
    {
      label: "Mark as other",
      value: DATE_TYPES.OTHER_PERIOD,
      color: "text-gray-500",
      icon: faDoorOpen,
    },
    {
      label: "Mark as holiday",
      value: DATE_TYPES.HOLIDAY_PERIOD,
      color: "text-[#F26311]",
      icon: faTreePalm,
    },
  ]

  useEffect(() => {
    if (!!user?._dates) {
      const _periods = user?._dates?.filter?.((d) => OoOTypes.includes(d.type))
      setPeriods(_periods)
      if (!!selectedUser?.period) {
        const found = _periods?.find?.((p) => p.key === selectedUser.period.key)
        setSelectedPeriod(found)
      }
    }
  }, [user, selectedUser])

  useEffect(() => {
    const othersDates =
      user?._dates?.filter((d) => !OoOTypes.includes(d.type)) ?? []
    const newDates = [...othersDates, ...periods]
    onChange(uid, newDates)
  }, [periods])

  const onTypeChange = (period: IDate, type: OutOfOfficeDateTypes) => {
    const newSelected: IDate = { ...period, type }
    setSelectedPeriod(newSelected)
    const updatedPeriods = periods.map((p) => {
      if (p.key === newSelected.key) return newSelected
      return p
    })
    setPeriods(updatedPeriods)
    onChange(uid, updatedPeriods)
  }

  const onDeletePeriod = (key: string) => {
    if (selectedPeriod?.key === key) setSelectedPeriod(undefined)
    const updatedPeriods = periods.filter((p) => p.key !== key)
    setPeriods(updatedPeriods)
    onChange(uid, updatedPeriods)
  }

  const onChangePeriod = (key: keyof IDate, value: any) => {
    setSelectedPeriod((prev) => ({ ...prev, [key]: value }))
    const updatedPeriods = periods.map((p) => {
      if (p.key === selectedPeriod?.key) return { ...p, [key]: value }
      return p
    })
    setPeriods(updatedPeriods)
    onChange(uid, updatedPeriods)
  }

  const onDateChange = (key: "start" | "end", date: Date) => {
    if (!date) return
    if (key === "start") date.setHours(23, 59, 59, 999)
    if (key === "end") date.setHours(0, 0, 0, 0)
    onChangePeriod(key, date.toISOString())
  }

  const onEditPeriod = (key: keyof IDate, data: IDate | Date) => {
    if ((key === "start" || key === "end") && data instanceof Date) {
      onDateChange(key, data)
    } else {
      onChangePeriod(key, data)
    }
  }

  const onAddPeriod = () => {
    const clone = [...(periods ?? [])]
    const lastPeriod = clone[clone.length - 1]
    let date = dayjs()
    if (lastPeriod) {
      const end = dayjs(lastPeriod.end)
      date = end.add(1, "day")
    }
    const stringifiedDate = date.toISOString()
    const newPeriod: IDate = {
      key: v4(),
      type: DATE_TYPES.OTHER_PERIOD,
      start: stringifiedDate,
      end: stringifiedDate,
      data: {
        allocation: 100,
      },
    }
    if (clone.length === 0) {
      setSelectedPeriod(newPeriod)
    }
    clone.push(newPeriod)
    setPeriods(clone)
  }

  const renderPeriods = () => {
    return periods?.map((p, i) => (
      <Period
        key={i}
        period={p}
        isSelected={selectedPeriod?.key === p.key}
        options={periodOptions}
        onSelect={() => setSelectedPeriod(p)}
        onMarkAs={(type) => onTypeChange(p, type as OutOfOfficeDateTypes)}
        onDelete={() => onDeletePeriod(p.key)}
        {...(showAvatar && { user })}
      />
    ))
  }

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        {isLoading
          ? Array.from({ length: 2 }).map((_, i) => (
              <SkeletonPeriod key={`skeleton-container-${i.toString()}`} />
            ))
          : renderPeriods()}
        {alertState?.users?.length === 1 && (
          <button
            onClick={onAddPeriod}
            className="mt-4 text-xs font-medium text-blue-500"
          >
            + Add period
          </button>
        )}
      </div>
      {!!selectedPeriod && (
        <EditPeriod period={selectedPeriod} onChange={onEditPeriod} />
      )}
    </div>
  )
}
