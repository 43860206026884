import { DatePicker } from "@/shared/components/ui/date-picker"
import { IDate } from "@/types/glossary"
import { isValidDate } from "@/utils/helpers"
import { Slider } from "antd"

type EditPeriod = {
  period: IDate
  onChange: (key: keyof IDate, period: IDate) => void
}

export const EditPeriod = ({ period, onChange }) => {
  const startDate = isValidDate(period?.start) ? new Date(period?.start) : null
  const endDate = isValidDate(period?.end) ? new Date(period?.end) : null

  return (
    <div className="mt-2">
      <div>
        <p className="text-xs font-medium text-blue-500 transition-all duration-300 group-focus:text-blue-500">
          Allocation
        </p>
        <Slider
          value={period?.data?.allocation}
          onChange={(value) =>
            onChange("data", { ...(period?.data ?? {}), allocation: value })
          }
          step={5}
          tooltip={{ formatter: (value) => `${value}%` }}
          trackStyle={{ backgroundColor: "var(--color-blue-500)" }}
        />
      </div>
      <div>
        <p className="mb-1 text-xs font-medium transition-all duration-300 group-focus-within:text-blue-500">
          Start date
        </p>
        <DatePicker
          date={startDate}
          defaultMonth={startDate}
          placeholder="Select a start date"
          onChange={(date) => onChange("start", date)}
        />
      </div>
      <div>
        <p className="mb-1 text-xs font-medium transition-all duration-300 group-focus-within:text-blue-500">
          End date
        </p>
        <DatePicker
          date={endDate}
          defaultMonth={endDate}
          placeholder="Select an end date"
          disabled={
            isValidDate(period?.start)
              ? { before: new Date(period?.start) }
              : false
          }
          onChange={(date) => onChange("end", date)}
        />
      </div>
    </div>
  )
}
