import dayjs from "dayjs"
import { upperFirst } from "lodash"
import { DAYS_PER_YEAR, MONTHS_PER_YEAR } from "./constants"

export const buildMonthCells = (startYear: number, numOfYears = 1) => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * numOfYears; i += 1) {
    const startMonth = i
    const start = addMonthsToYearAsDate(startYear, startMonth)
    const end = addMonthsToYearAsDate(startYear, startMonth + 1)
    v.push({
      id: `m${startMonth}`,
      title: `${upperFirst(dayjs(start).format("MMM"))} ${startYear + Math.floor(i / 12)}`,
      start,
      end,
    })
  }
  return v
}

export const buildCells = (
  timebar: string,
  props: { startYear: number; numOfYears?: number },
) => {
  switch (timebar) {
    case "months": {
      return buildMonthCells(props.startYear, props.numOfYears)
    }
  }
}

export const addMonthsToYear = (year, monthsToAdd) => {
  let y = year
  let m = monthsToAdd
  while (m >= MONTHS_PER_YEAR) {
    m -= MONTHS_PER_YEAR
    y += 1
  }
  return { year: y, month: m + 1 }
}

export const addMonthsToYearAsDate = (year, monthsToAdd) => {
  const r = addMonthsToYear(year, monthsToAdd)
  return new Date(`${r.year}/${r.month}/01`)
}

export const addDaysToYear = (year, daysToAdd) => {
  let y = year
  let d = daysToAdd

  while (d >= DAYS_PER_YEAR) {
    d -= DAYS_PER_YEAR
    y += 1
  }

  return { year: y, day: d + 1 }
}
