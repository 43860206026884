import { IField } from "@/features/fields/types"
import { fetcher } from "@/utils/api"
import useSWR from "swr"

export const useFields = () => {
  const { data, ...rest } = useSWR<IField[]>(
    `/apps/52e7c717-d7fe-48fb-8916-61b8fea4ecbb/data/fields`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  )
  return { fields: data ?? [], ...rest }
}
