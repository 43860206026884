import { addMonthsToYearAsDate } from "@/components/timeline/helpers/builders"
import {
  BORDER_HEIGHT,
  CELL_HEIGHT,
  CELL_WIDTH,
  MILLIS_IN_A_DAY,
  MONTHS_PER_YEAR,
  PERIOD_ELEMENT_HEIGHT,
} from "@/components/timeline/helpers/constants"
import { ITrackElement } from "@/components/timeline/helpers/types"
import {
  getEndDateOfYear,
  getLastDayOfMonth,
  getOverlappingElements,
  getStartDateOfYear,
} from "@/components/timeline/helpers/utils"
import { IDate } from "@/types/glossary"
import { getAllocationDates, isValidDate, isWithinRange } from "@/utils/helpers"
import dayjs from "dayjs"
import { fill } from "lodash"
import { AllocationElement, PeriodElement } from "../components/TrackElements"

export const calculateAllocations = (
  talents: Record<string, any>[],
  start: Date,
  end: Date,
): { hired: number; potential: number; extension: number } => {
  return talents.reduce(
    (acc, t) => {
      const periods: IDate[] = getAllocationDates(t?._dates)
      periods.map((p) => {
        if (!p) return
        if (
          isWithinRange(p.start, p.end, start, end) &&
          ["hired", "potential", "extension"].includes(p?.data?.status)
        ) {
          acc[p?.data?.status] += isNaN(Number(p?.data?.allocation))
            ? 0
            : Number(p?.data?.allocation)
        }
      })
      return acc
    },
    { hired: 0, potential: 0, extension: 0 },
  ) as { hired: number; potential: number; extension: number }
}

export const buildAllocationElements = (
  {
    periods,
    talents,
    dates,
    currentStartDate,
  }: {
    periods: IDate[]
    talents?: Record<string, any>[]
    dates: { start: Date; end: Date }
    currentStartDate: Date
  },
  zoom: number,
): ITrackElement[] => {
  const numOfYears = dates.end.getFullYear() - dates.start.getFullYear()
  const numOfElements = MONTHS_PER_YEAR * (numOfYears + 1)

  return fill(Array(numOfElements), null).map((_, i) => {
    const startMonth = new Date(dates.start.getFullYear(), i, 1)
    const endMonth = new Date(dates.start.getFullYear(), i + 1, 1)

    const diffDays = Math.round(
      (startMonth.getTime() - currentStartDate.getTime()) / MILLIS_IN_A_DAY,
    )
    const month = addMonthsToYearAsDate(dates.start.getFullYear(), i)
    const daysInMonth = getLastDayOfMonth(month)

    return {
      uid: `element-${i}-allocation`,
      title: (
        <AllocationElement
          periods={periods}
          talents={talents}
          start={startMonth}
          end={endMonth}
        />
      ),
      start: diffDays * (CELL_WIDTH * zoom),
      end: daysInMonth * (CELL_WIDTH * zoom),
    }
  })
}

export const buildPeriodElements = (
  {
    talent,
    users,
    tasks,
    periods,
    dates,
    currentStartDate,
    currentEndDate,
  }: {
    talent?: string
    users?: string[]
    tasks?: string[]
    periods: IDate[]
    dates: { start: Date; end: Date }
    currentStartDate: Date
    currentEndDate: Date
  },
  zoom: number,
): ITrackElement[] => {
  if (!periods.length) {
    const start = getStartDateOfYear(dates.start)
    const end = getEndDateOfYear(dates.end)
    const offsetInDays = Math.round(
      (start.getTime() - currentStartDate.getTime()) / MILLIS_IN_A_DAY,
    )
    const endInDays = Math.round(
      (end.getTime() - start.getTime()) / MILLIS_IN_A_DAY,
    )
    return [
      {
        uid: `element-${talent}-period`,
        title: (
          <PeriodElement
            start={null}
            end={null}
            period={undefined}
            talent={talent}
            users={users}
            tasks={tasks}
          />
        ),
        start: offsetInDays * (CELL_WIDTH * zoom),
        end: endInDays * (CELL_WIDTH * zoom),
      },
    ]
  }

  return periods.reduce((acc, p) => {
    const { start, end } = p
    const startDate = new Date(dayjs(start).format("YYYY-MM-DD"))
    const endDate = new Date(
      dayjs(isValidDate(end) ? end : dates.end).format("YYYY-MM-DD"),
    )

    if (
      (startDate.getTime() >= currentStartDate.getTime() &&
        startDate.getTime() <= currentEndDate.getTime()) ||
      endDate.getTime() <= currentEndDate.getTime()
    ) {
      const offsetInDays = Math.round(
        (startDate.getTime() - currentStartDate.getTime()) / MILLIS_IN_A_DAY,
      )
      let endInDays =
        Math.round(
          (endDate.getTime() - startDate.getTime()) / MILLIS_IN_A_DAY,
        ) + 1
      const daysLeftInYear = Math.round(
        (currentEndDate.getTime() - startDate.getTime()) / MILLIS_IN_A_DAY,
      )
      endInDays = Math.min(endInDays, daysLeftInYear)

      const element: ITrackElement = {
        uid: `element-${p.key}-period`,
        title: "",
        start: offsetInDays * (CELL_WIDTH * zoom),
        end: endInDays * (CELL_WIDTH * zoom),
      }

      const overlappingElements = getOverlappingElements([...acc, element])
      const index = overlappingElements.findIndex((el) => el === element.uid)
      let topOffset = (CELL_HEIGHT + BORDER_HEIGHT - PERIOD_ELEMENT_HEIGHT) / 2
      if (index > -1)
        topOffset += (CELL_HEIGHT + BORDER_HEIGHT) * Math.max(index, 0)
      element.title = (
        <PeriodElement
          start={isValidDate(start) ? dayjs(start) : null}
          end={isValidDate(end) ? dayjs(end) : null}
          top={topOffset}
          period={p}
          talent={talent}
          users={users}
          tasks={tasks}
        />
      )
      acc.push(element)
    }
    return acc
  }, [] as ITrackElement[])
}
