import { BasicButton } from "@/components/buttons/BasicButton"
import { GenericCollapse } from "@/shared/components/GenericCollapse"
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "@/shared/components/ui/dialog"
import { DATE_TYPES, IDate } from "@/types/glossary"
import { updateNodeContent } from "@/utils/api/nodes"
import { faChevronDown } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { toast } from "sonner"
import { useSWRConfig } from "swr"
import {
  selectedTaskAtom,
  selectedUserAtom,
  timelineAlertAtom,
} from "../utils/atoms"
import { PriceAdjustments } from "./PriceAdjustments"
import { UserPeriods } from "./UserPeriods"

type DateTypes = keyof typeof DATE_TYPES

export type OutOfOfficeDateTypes = Exclude<DateTypes, "ALLOCATION_PERIOD">

export const OoOTypes = ["OTHER_PERIOD", "HOLIDAY_PERIOD"] as const

export const ModalOutOfOffice = () => {
  const [alertData, setAlertData] = useRecoilState(timelineAlertAtom)
  const [changes, setChanges] = useState<
    Record<string, IDate[] | Record<string, string>>
  >({})
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const setSelectedUser = useSetRecoilState(selectedUserAtom)
  const setSelectedTask = useSetRecoilState(selectedTaskAtom)
  const { mutate, cache } = useSWRConfig()

  const onSave = async () => {
    setIsSaving(true)
    try {
      await Promise.all(
        Object.entries(changes).map(async ([uid, data]) => {
          const isPriceAdjustment = !Array.isArray(data)
          if (isPriceAdjustment) {
            await updateNodeContent(uid, data)
            mutate(`/tasks/${uid}`, (task: any) => ({ ...task, ...data }))
          } else {
            await updateNodeContent(uid, { _dates: data })
            mutate(`/users/${uid}`, (user: any) => ({ ...user, _dates: data }))
          }
        }),
      )
      onClose()
      updateUserTimelineData()
      updateTaskTimelineData()
      toast.success("Changes saved successfully")
    } catch (error) {
      toast.error("Failed to save changes")
    } finally {
      setIsSaving(false)
    }
  }

  const updateUserTimelineData = () => {
    //@ts-ignore
    for (const key of cache.keys()) {
      if (key.includes("/timeline/users")) {
        mutate(
          key,
          (data) => {
            const users = Array.isArray(data?.[0]) ? data.flat() : data
            return users.map((u) => {
              let user = u
              const dates = changes[user.uid]
              if (!!dates) {
                u = {
                  ...user,
                  _dates: dates,
                }
              }
              if (u?.talents?.length > 0) {
                u.talents = u.talents.map((t) => {
                  const data = changes[t?._task?.uid]
                  if (!!data) {
                    return {
                      ...t,
                      _task: {
                        ...t._task,
                        ...data,
                      },
                    }
                  }
                  return t
                })
              }
              return u
            })
          },
          false,
        )
      }
    }
  }

  const updateTaskTimelineData = () => {
    //@ts-ignore
    for (const key of cache.keys()) {
      if (key.includes("/timeline/tasks")) {
        mutate(
          key,
          (data) => {
            const tasks = Array.isArray(data?.[0]) ? data.flat() : data
            return tasks.map((task) => {
              let t = task
              const data = changes[t.uid]
              if (!!data) {
                t = {
                  ...t,
                  ...data,
                }
              }
              if (t?._talents?.length > 0) {
                t._talents = t._talents.map((t) => {
                  const _dates = changes[t?._space_user?.uid]
                  if (!!_dates) {
                    return {
                      ...t,
                      _space_user: {
                        ...t._space_user,
                        _dates,
                      },
                    }
                  }
                  return t
                })
              }
              return t
            })
          },
          false,
        )
      }
    }
  }

  const onClose = () => {
    setAlertData({ open: false, users: [], tasks: [] })
    setSelectedUser(null)
    setSelectedTask(null)
    setChanges({})
  }

  const onPeriodChange = (uid: string, dates: IDate[]) => {
    setChanges((prev) => ({ ...prev, [uid]: dates }))
  }

  const onPriceAdjustmentChange = (uid: string, kv: Record<string, string>) => {
    setChanges((prev) => ({ ...prev, [uid]: { ...(prev[uid] ?? {}), ...kv } }))
  }

  return (
    <Dialog
      open={alertData.open}
      onOpenChange={(open) => {
        if (!open) onClose()
      }}
    >
      <DialogContent>
        <DialogHeader className="text-lg font-medium">{`Edit important alerts`}</DialogHeader>
        <GenericCollapse
          defaultOpen
          renderButton={({ open }) => (
            <div className="mb-2 flex w-full items-center justify-between">
              <hr className="w-4 bg-gray-400" />
              <div className="flex flex-shrink select-none gap-2 px-2 text-xs font-medium text-gray-400">
                <span className="font-medium">Out of office</span>
              </div>
              <hr className="flex-1 bg-gray-400" />
              <FontAwesomeIcon
                className={`select-none px-2 text-xs text-gray-400 ${!open ? "rotate-180" : ""}`}
                icon={faChevronDown}
              />
              <hr className="w-4 bg-gray-400" />
            </div>
          )}
        >
          <div className="space-y-2">
            <p className="text-sm font-medium">Out of office</p>
            {alertData.users?.map((user) => (
              <UserPeriods
                key={user}
                user={user}
                showAvatar={
                  alertData?.users?.length > 1 || alertData?.tasks?.length === 1
                }
                onChange={onPeriodChange}
              />
            ))}
          </div>
        </GenericCollapse>
        <GenericCollapse
          defaultOpen
          renderButton={({ open }) => (
            <div className="mb-2 flex w-full items-center justify-between">
              <hr className="w-4 bg-gray-400" />
              <div className="flex flex-shrink select-none gap-2 px-2 text-xs font-medium text-gray-400">
                <span className="font-medium">Price adjustments</span>
              </div>
              <hr className="flex-1 bg-gray-400" />
              <FontAwesomeIcon
                className={`select-none px-2 text-xs text-gray-400 ${!open ? "rotate-180" : ""}`}
                icon={faChevronDown}
              />
              <hr className="w-4 bg-gray-400" />
            </div>
          )}
        >
          <PriceAdjustments onChange={onPriceAdjustmentChange} />
        </GenericCollapse>
        <div className="flex gap-2">
          <BasicButton className="w-full" onClick={onClose}>
            Close
          </BasicButton>
          <BasicButton
            disabled={isSaving}
            loading={{ loading: isSaving, color: "white", size: 14 }}
            className="w-full"
            variant="primary"
            onClick={onSave}
          >
            Save
          </BasicButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
