import { DATE_TYPES, IDate } from "@/types/glossary"
import { HEX_OPACITY } from "@/utils/constants"
import { cn } from "@/utils/helpers"
import { ComponentType, HTMLAttributes } from "react"

type PillProps = {
  period: IDate
}

const AllocationPill = ({ period }: PillProps) => {
  const isExtension = period?.data?.status === "extension"
  const isPotential = period?.data?.status === "potential"
  const isHired = period?.data?.status === "hired"

  const getPillText = () => {
    switch (period.data?.status) {
      case "hired":
        return "Hired"
      case "potential":
        return "Potential"
      default:
        return "Extension"
    }
  }

  return (
    <Pill
      style={
        isExtension
          ? {
              background:
                "repeating-linear-gradient(-55deg, #f3f4f6, #f3f4f6 4px, #f9fafb 4px, #f9fafb 10px)",
            }
          : {}
      }
      className={cn(
        "text-[10px rounded-md px-2 py-px",
        isPotential && "bg-blue-100 text-blue-500",
        isHired && "bg-[#0ADE90]/10 text-[#0ADE90]",
      )}
    >
      <span>{getPillText()}</span>
    </Pill>
  )
}

const HolidayPill = () => {
  return (
    <Pill style={{ backgroundColor: "#F26311" + HEX_OPACITY[15] }}>
      <span style={{ color: "#F26311" }}>Holiday</span>
    </Pill>
  )
}

const OtherPill = () => {
  return (
    <Pill className="bg-gray-100">
      <span style={{ color: "#000" }}>Other</span>
    </Pill>
  )
}

const Pill = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cn("text-[10px rounded-md px-2 py-px", className)}
    >
      {children}
    </div>
  )
}

export const PeriodPills: Record<
  keyof typeof DATE_TYPES,
  ComponentType<PillProps>
> = {
  [DATE_TYPES.ALLOCATION_PERIOD]: AllocationPill,
  [DATE_TYPES.HOLIDAY_PERIOD]: HolidayPill,
  [DATE_TYPES.OTHER_PERIOD]: OtherPill,
  [DATE_TYPES.PRICE_ADJUSTMENT]: null,
}
