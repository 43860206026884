import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/core/components/HoverCard"
import { Icon } from "@/features/icons/components/IconLoader"
import { INote } from "@/features/people/types"
import { fetcher } from "@/utils/api"
import { USERS_APP } from "@/utils/api/apps"
import { parseName } from "@/utils/helpers"
import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faStickyNote,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "next-i18next"

type HoverCardNotesProps = {
  userId: string
  onExpand?: () => void
}

export const HoverCardNotes = ({ userId, onExpand }: HoverCardNotesProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation(["common", "users"])

  const { data: notes, isLoading } = useQuery<INote[]>({
    queryKey: ["notes", userId],
    queryFn: async () => fetcher(`/apps/${USERS_APP}/data/${userId}/notes`),
    enabled: !!userId && open,
  })

  return (
    <HoverCard open={open} onOpenChange={setOpen} openDelay={300}>
      <HoverCardTrigger
        onClick={(e) => e.stopPropagation()}
        className="grid place-content-center rounded-full ring-gray-200 hover:ring-8"
      >
        <Icon icon={faStickyNote} className="h-4 w-4" />
      </HoverCardTrigger>
      <HoverCardContent
        className="flex max-h-96 w-96 cursor-default flex-col p-0 pt-2"
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading && (
          <p className="text-sm font-normal">{t("common:loading")}</p>
        )}
        {!isLoading && !notes?.length && (
          <p className="text-muted-foreground text-sm">
            {t("users:notes.no_notes_found")}
          </p>
        )}
        <div className="flex w-full flex-1 flex-col divide-y overflow-y-auto p-2">
          {notes?.map((note) => (
            <div key={note.uid} className="space-y-1 py-2">
              <p
                className="text-start text-sm font-normal"
                dangerouslySetInnerHTML={{ __html: note.text }}
              />
              <div className="flex items-center justify-between pt-2">
                <p className="text-xs font-normal text-gray-500">
                  {parseName(note.createdby)}
                </p>
                <span className="text-xs font-normal text-gray-500">
                  {dayjs(note.created).format("MMM D, YYYY")}
                </span>
              </div>
            </div>
          ))}
        </div>
        {!!onExpand && (
          <div className="grid h-fit border-t">
            <button
              onClick={() => {
                setOpen(false)
                onExpand()
              }}
              className="hover:bg-surface h-12 text-sm"
            >
              <FontAwesomeIcon
                className="mr-2"
                icon={faArrowUpRightAndArrowDownLeftFromCenter}
              />
              {t("users:notes.open_in_popup")}
            </button>
          </div>
        )}
      </HoverCardContent>
    </HoverCard>
  )
}
