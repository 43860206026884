import { CELL_WIDTH } from "./constants"
import { ITrackElement } from "./types"

export const getLastDayOfMonth = (date: Date) => {
  const lastDay = new Date(date)
  lastDay.setDate(32)
  return new Date(lastDay.getFullYear(), lastDay.getMonth(), 0).getDate()
}

export const getStartDateOfYear = (date = new Date()) => {
  const newDate = new Date(date)
  newDate.setMonth(0)
  newDate.setDate(1)
  newDate.setHours(0)
  newDate.setMinutes(0)
  return newDate
}

export const getEndDateOfYear = (date: Date) => {
  const newDate = new Date(date)
  newDate.setMonth(11)
  newDate.setDate(31)
  newDate.setHours(23)
  newDate.setMinutes(59)
  return newDate
}

const MILLIS_IN_A_DAY = 24 * 60 * 60 * 1000

type CreatTimeProps = {
  start: Date
  end: Date
  zoom?: number
  viewportWidth?: number
  minWidth?: number
}

export const createTime = ({
  start,
  end,
  zoom,
  viewportWidth = 0,
  minWidth = 0,
}: CreatTimeProps) => {
  const duration = end.getTime() - start.getTime()

  const days = duration / MILLIS_IN_A_DAY

  let timelineWidth = days * (CELL_WIDTH * zoom)

  const timelineWidthStyle = `${timelineWidth}px`

  const toX = (from: number) => {
    const value = (from - start.getTime()) / duration
    return Math.round(value * timelineWidth)
  }

  const toStyleLeft = (from: number) => ({
    left: `${toX(from)}px`,
  })

  const toStyleLeftAndWidth = (from: number, to: number) => {
    const left = toX(from)
    return {
      left: `${left}px`,
      width: `${toX(to) - left}px`,
    }
  }

  const fromX = (x: number) =>
    new Date(start.getTime() + (x / timelineWidth) * duration)

  return {
    timelineWidth,
    timelineWidthStyle,
    start,
    end,
    zoom,
    toX,
    toStyleLeft,
    toStyleLeftAndWidth,
    fromX,
  }
}

const pixelTolerance = 1

export const getOverlappingElements = (elements: ITrackElement[]): string[] => {
  return elements.reduce((acc, element) => {
    const isOverlapping = elements.some((el) => {
      return (
        el.uid !== element.uid &&
        el.start + el.end > element.start + pixelTolerance &&
        element.end + element.start > el.start + pixelTolerance
      )
    })
    if (isOverlapping && !acc.includes(element.uid)) acc.push(element.uid)
    return acc
  }, [])
}
