import { IDate } from "@/types/glossary"
import { atom } from "recoil"

export const selectedTalentAtom = atom<{ talent: string; period?: IDate }>({
  key: "selectedTalent",
  default: null,
})

export const candidateFlowAtom = atom<{ task: string; open: boolean }>({
  key: "candidateFlow",
  default: {
    task: null,
    open: false,
  },
})

export const selectedUserAtom = atom<{ user: string; period?: IDate }>({
  key: "selectedUser",
  default: null,
})

export const selectedTaskAtom = atom<string>({
  key: "selectedTask",
  default: null,
})

export const timelineAlertAtom = atom<{
  users?: string[]
  tasks?: string[]
  open: boolean
}>({
  key: "timelineAlert",
  default: {
    users: [],
    tasks: [],
    open: false,
  },
})

export const timelineNotesAtom = atom<{ user: string; open: boolean }>({
  key: "timelineNotes",
  default: {
    user: null,
    open: false,
  },
})
