import {
  PERIOD_ELEMENT_HEIGHT,
  SIDE_BAR_WIDTH,
} from "@/components/timeline/helpers/constants"
import { Icon } from "@/features/icons/components/IconLoader"
import { DATE_TYPES, IDate } from "@/types/glossary"
import { fetcher } from "@/utils/api"
import { cn, parseName } from "@/utils/helpers"
import {
  faCheck,
  faMoneyBillTransfer,
  faTreePalm,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import { faDoorOpen } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { ComponentType, useRef } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import useSWR from "swr"
import {
  selectedTalentAtom,
  selectedTaskAtom,
  selectedUserAtom,
  timelineAlertAtom,
} from "../utils/atoms"

type ElementProps = {
  start: Dayjs
  end: Dayjs
  top?: number | null
  icon: string
  period: IDate
  talent?: string
  users?: string[]
  tasks?: string[]
}

const AllocationElement = ({
  start,
  end,
  top = 0,
  period,
  icon,
  talent,
}: ElementProps) => {
  const [selectedTalent, setSelectedTalent] = useRecoilState(selectedTalentAtom)
  const { data: prices } = useSWR(
    period?.key ? `/periods/${period.key}/prices` : null,
    fetcher,
  )
  const price = prices?.[0]
  const elementRef = useRef<HTMLDivElement>(null)
  const isSelected = selectedTalent?.period?.key === period.key
  const isHired = period?.data?.status === "hired"
  const isPotential = period?.data?.status === "potential"
  const isExtension = period?.data?.status === "extension"
  const isFuture = start?.isAfter(dayjs())
  let backgroundStyle

  if (isExtension && isFuture) {
    backgroundStyle = {
      background:
        "repeating-linear-gradient(-55deg, #f3f4f6, #f3f4f6 4px, #f9fafb 4px, #f9fafb 10px)",
    }
  } else if (isPotential && isFuture) {
    backgroundStyle = {
      background:
        "repeating-linear-gradient(-55deg, #dbeafe, #dbeafe 4px, #eff6ff 4px, #eff6ff 10px)",
    }
  } else if (isHired && isFuture) {
    backgroundStyle = {
      background:
        "repeating-linear-gradient(-55deg, #0ADE9033, #0ADE9033 4px, #0ADE901A 4px, #0ADE901A 10px)",
    }
  }

  return (
    <div
      ref={elementRef}
      style={{ top, maxHeight: PERIOD_ELEMENT_HEIGHT }}
      className={cn(
        "pointer-events-auto absolute w-full transition-all ease-in-out hover:z-40 hover:scale-[1.01] hover:shadow-lg",
        isSelected && "z-40 scale-[1.01] shadow-lg",
      )}
    >
      <div
        onClick={() => setSelectedTalent({ talent, period })}
        style={backgroundStyle}
        className={cn(
          "z-20 flex flex-grow cursor-pointer select-none items-center justify-between rounded-md border bg-gray-100 px-2 py-1",
          isPotential && "border-blue-400 bg-blue-100",
          isHired && "border-[#0ADE90] bg-[#0ADE90]/10",
        )}
      >
        <div className="relative flex-grow">
          <div
            style={{ left: SIDE_BAR_WIDTH + 64 }}
            className="sticky flex w-fit items-center gap-2"
          >
            <Icon
              className={cn(
                isPotential && "text-blue-400",
                isHired && "text-[#0ADE90]",
              )}
              icon={icon}
            />
            <div>
              <p className="line-clamp-1 text-xs font-medium">
                {`${price ? `${price.price / 100} ${price?.currency?.prefix ?? "Unknown"} -` : ""} ${
                  period?.data?.allocation ? period?.data?.allocation : 0
                }%`}
              </p>
              <p className="line-clamp-1 text-[8px] text-gray-500">
                {start ? start.format("DD MMM YY") : ""} -{" "}
                {end ? end.format("DD MMM YY") : "Future"}
              </p>
            </div>
          </div>
        </div>
        {elementRef.current?.offsetWidth > 150 &&
          ["hired", "potential"].includes(period?.data?.status) && (
            <div
              className={cn(
                "rounded-md px-2 py-1 text-[10px]",
                isPotential && "bg-blue-400 text-white",
                isHired && "bg-[#0ADE90] text-white",
              )}
            >
              <span>{isHired ? "Hired" : "Potential booking"}</span>
            </div>
          )}
      </div>
    </div>
  )
}

const AlertElement = ({
  start,
  end,
  top = 0,
  period,
  users,
  tasks,
}: ElementProps) => {
  const [selectedUser, setSelectedUser] = useRecoilState(selectedUserAtom)
  const setAlertState = useSetRecoilState(timelineAlertAtom)

  const isSelected = selectedUser?.period?.key === period.key
  const className = "bg-gray-200 text-gray-500 border-gray-300"
  const username = !!period?.data?.user ? parseName(period?.data?.user) : ""
  const _user = period?.data?.user?.uid ?? users?.[0]
  let icon = faDoorOpen
  switch (period.type) {
    case DATE_TYPES.HOLIDAY_PERIOD:
      icon = faTreePalm
      break
    case DATE_TYPES.OTHER_PERIOD:
      icon = faDoorOpen
      break
    default:
      break
  }

  const onClick = () => {
    setAlertState({ open: true, users: [_user], tasks })
    setSelectedUser({ user: _user, period })
  }

  return (
    <Tooltip
      title={
        start
          ? `${start.format("DD MMM YY")} - ${end ? end.format("DD MMM YY") : "Future"} ${!!username ? `(${username})` : ""}`
          : ""
      }
    >
      <div
        style={{ top, maxHeight: PERIOD_ELEMENT_HEIGHT }}
        className={cn(
          "pointer-events-auto absolute h-full w-full transition-all ease-in-out hover:z-40 hover:scale-[1.01] hover:shadow-lg",
          isSelected && "z-40 scale-[1.01] shadow-lg",
        )}
      >
        <div
          onClick={_user ? onClick : null}
          className={cn(
            "z-20 flex h-full flex-grow select-none items-center justify-between rounded-xl border px-2 py-1",
            className,
            !!_user && "cursor-pointer",
          )}
        >
          <div className="relative flex-grow">
            <div
              style={{ left: SIDE_BAR_WIDTH + 64 }}
              className="sticky flex w-full items-center justify-center gap-2"
            >
              <Icon icon={icon} />
              <span className="text-sm">
                {period?.data?.allocation && `${period.data.allocation}%`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

const PriceAdjustmentElement = ({
  top = 0,
  period,
  users,
  tasks,
}: ElementProps) => {
  const [selectedTask, setSelectedTask] = useRecoilState(selectedTaskAtom)
  const setAlertData = useSetRecoilState(timelineAlertAtom)
  const isSelected = tasks?.[0] === selectedTask
  const priceAdjustmentDate = period?.data?.date
  const taskName = period?.data?.task?.name
  const uid = period?.data?.task?.uid ?? tasks?.[0]
  const isResolved = period?.data?.task?._price_resolved

  const onClick = () => {
    setSelectedTask(uid)
    setAlertData({ open: true, users, tasks })
  }

  return (
    <Tooltip title={taskName ? taskName : ""}>
      <div
        style={{ top, maxHeight: PERIOD_ELEMENT_HEIGHT }}
        className={cn(
          "pointer-events-auto absolute h-full w-full transition-all ease-in-out hover:z-40 hover:scale-[1.01] hover:shadow-lg",
          isSelected && "z-40 scale-[1.01] shadow-lg",
        )}
      >
        <div
          onClick={onClick}
          className={cn(
            "relative z-20 flex h-full flex-grow cursor-pointer select-none items-center justify-between rounded-xl border bg-red-400 px-4 py-1",
          )}
        >
          <div className="relative flex-grow">
            <div
              style={{ left: SIDE_BAR_WIDTH + 64 }}
              className="sticky flex w-full items-center gap-2 text-white"
            >
              <Icon icon={faMoneyBillTransfer} />
              <p className="text-[11px] font-light">
                Price adjustment -{" "}
                <span className="font-medium">
                  {priceAdjustmentDate
                    ? `${dayjs(priceAdjustmentDate).format("DD MMM YY")}`
                    : "No date"}
                </span>
              </p>
            </div>
          </div>
        </div>
        {isResolved && (
          <div className="absolute -bottom-1 -right-1 z-30 grid h-4 w-4 place-content-center rounded-full bg-green-400">
            <FontAwesomeIcon icon={faCheck} color="white" size="xs" />
          </div>
        )}
      </div>
    </Tooltip>
  )
}

export const PeriodElements: Record<
  keyof typeof DATE_TYPES,
  ComponentType<ElementProps>
> = {
  [DATE_TYPES.ALLOCATION_PERIOD]: AllocationElement,
  [DATE_TYPES.HOLIDAY_PERIOD]: AlertElement,
  [DATE_TYPES.OTHER_PERIOD]: AlertElement,
  [DATE_TYPES.PRICE_ADJUSTMENT]: PriceAdjustmentElement,
}
