export const MONTHS_PER_YEAR = 12
export const QUARTERS_PER_YEAR = 4
export const MONTHS_PER_QUARTER = 3
export const WEEKS_PER_YEAR = 52
export const DAYS_PER_YEAR = 365
export const CELL_WIDTH = 8
export const CELL_HEIGHT = 54
export const PERIOD_ELEMENT_HEIGHT = 38
export const BORDER_HEIGHT = 1
export const SIDE_BAR_WIDTH = 300
export const MILLIS_IN_A_DAY = 24 * 60 * 60 * 1000
